@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

*,
a,
button {
  box-sizing: border-box;
  outline: none;
}

.padding15vw {
  padding-left: 5vw;
  padding-right: 5vw;
}

a {
  color: #000;
}

.note {
  display: inline-block;
  position: relative;
  align-self: flex-end;
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 5px;
  background-color: #f8e75d;
  max-width: 600px;
  z-index: 1;
  color: #333;
}

.note:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f8e75d;
  position: absolute;
  right: 20px;
  bottom: -10px;
  overflow: hidden;
}

.addNote {
  color: #555;
  cursor: pointer;
  font-size: 16px !important;
  text-decoration: none;
  display: inline-block;
  align-self: flex-end;
  z-index: 1;
  position: absolute;
}

.editNote {
  color: #555;
  cursor: pointer;
  font-size: 14px !important;
  text-decoration: none;
  display: inline-block;
  margin-right: -20px;
  margin-left: 0.5em;
  opacity: 0;
  transition: 0.1s all ease-in-out;
}

.note:hover .editNote {
  opacity: 1;
  margin-right: 0;
}

.bigDatePicker > div:before {
  border: 0;
}

.bigDatePicker input {
  font-size: 1.6em;
  text-align: center;
  cursor: pointer;
}

.ordersTable td {
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
}

.ordersTable tr {
  height: 40px !important;
}

.smallButton {
  padding: 4px !important;
}

.inputEmpty {
  background-color: rgb(255, 240, 190) !important;
}

.dialogModal {
  min-width: 350px;
}

.update-notification {
  background-color: #ffffff;
  color: #222222;
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.8em 1.2em;
  text-decoration: none;
  cursor: pointer;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
  }
}

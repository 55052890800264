@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

*,
a,
button {
  box-sizing: border-box;
  outline: none;
}

.padding15vw {
  padding-left: 5vw;
  padding-right: 5vw;
}

a {
  color: #000;
}

.note {
  display: inline-block;
  position: relative;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 5px;
  background-color: #f8e75d;
  max-width: 600px;
  z-index: 1;
  color: #333;
}

.note:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f8e75d;
  position: absolute;
  right: 20px;
  bottom: -10px;
  overflow: hidden;
}

.addNote {
  color: #555;
  cursor: pointer;
  font-size: 16px !important;
  text-decoration: none;
  display: inline-block;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  z-index: 1;
  position: absolute;
}

.editNote {
  color: #555;
  cursor: pointer;
  font-size: 14px !important;
  text-decoration: none;
  display: inline-block;
  margin-right: -20px;
  margin-left: 0.5em;
  opacity: 0;
  transition: 0.1s all ease-in-out;
}

.note:hover .editNote {
  opacity: 1;
  margin-right: 0;
}

.bigDatePicker > div:before {
  border: 0;
}

.bigDatePicker input {
  font-size: 1.6em;
  text-align: center;
  cursor: pointer;
}

.ordersTable td {
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
}

.ordersTable tr {
  height: 40px !important;
}

.smallButton {
  padding: 4px !important;
}

.inputEmpty {
  background-color: rgb(255, 240, 190) !important;
}

.dialogModal {
  min-width: 350px;
}

.update-notification {
  background-color: #ffffff;
  color: #222222;
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.8em 1.2em;
  text-decoration: none;
  cursor: pointer;
}

@-webkit-keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
  }
}

.timetable {
  box-sizing: border-box;
  width: 100%;
  min-width: 1240px;
  display: -webkit-flex;
  display: flex;
}
.timetable::after {
  clear: both;
  content: "";
  display: table;
}
.timetable *,
.timetable *:before,
.timetable *:after {
  box-sizing: inherit;
}
.timetable ul,
.timetable li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.timetable aside {
  padding: 0 !important;
  margin-top: 36px;
  border-right: 5px solid transparent;
  min-width: 189px;
}

.timetable .amounts {
  width: 80px;
  margin-top: 45px;
  line-height: 25px;
  text-align: right;
}

.timetable .amounts li {
  line-height: 25px;
  margin-bottom: 7px;
  font-weight: 600;
}

.timetable aside li {
  margin-bottom: 7px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.timetable aside .row-heading {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1em;
}

.timetable aside .row-subheading {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1em;
}

.timetable ul {
  list-style-type: none;
  margin: 0;
}
.timetable aside li,
.timetable time li {
  height: 25px;
}
.timetable section {
  width: 1105px;
  padding: 0 !important;
  -webkit-overflow-scrolling: touch;
}
.timetable section time {
  white-space: nowrap;
  display: block;
}
.timetable section header {
  height: 46px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  font-size: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 26px;
  z-index: 999999999;
}

.timetable section header::after {
  clear: both;
  content: "";
  display: table;
}
.timetable section header li {
  display: inline-block;
  font-size: 1rem;
  overflow: visible;
  width: 0;
  line-height: 25px;
  display: inline-block;
  position: relative;
}
.timetable section header li:not(:last-of-type) {
  width: 46px;
}
.timetable section header li .time-label {
  display: block;
  position: absolute;
  left: 0;
  font-size: 0.8em;
  font-weight: 500;
}
.timetable section header li:not(:first-of-type) .time-label {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.timetable section header li:last-of-type .time-label {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.timetable ul.room-timeline {
  border-left: none;
  position: relative;
  overflow: hidden;
}
.timetable ul.room-timeline li {
  position: relative;
  margin-bottom: 7px;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  border-left: 1px solid #333;
}

.timetable ul.room-timeline li:first-child .time-entry {
  height: 23px;
}

.timetable ul.room-timeline li:after,
.timetable ul.room-timeline li:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.timetable ul.room-timeline li:after {
  background-image: linear-gradient(
    to right,
    #333,
    #333 1px,
    #f4f4f4 1px,
    #f4f4f4 1px,
    #333 1px,
    #333 1px,
    transparent 1px,
    transparent
  );
  background-size: 46px auto;
  background-position: -2px 0;
}
.timetable .time-entry {
  background-color: #ec6a5e;
  transition: 200ms background-color;
  height: 23px;
  display: block;
  position: absolute;
  z-index: 2;
  padding: 0 10px;
  white-space: normal;
  overflow: hidden;
  color: #000;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.timetable .time-entry.time-entry--pause small {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
}
.timetable .time-entry .cityLeft {
  display: inline-block;
  line-height: 23px;
  font-weight: 600;
}

.timetable .time-entry .cityRight {
  display: block;
  float: right;
  line-height: 23px;
  font-weight: 600;
}

.timetable .time-entry.time-entry--pause {
  background: #333;
  color: #fff;
}

.achtung {
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: red;
  z-index: 10;
}


.timetable {
  box-sizing: border-box;
  width: 100%;
  min-width: 1240px;
  display: flex;
}
.timetable::after {
  clear: both;
  content: "";
  display: table;
}
.timetable *,
.timetable *:before,
.timetable *:after {
  box-sizing: inherit;
}
.timetable ul,
.timetable li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.timetable aside {
  padding: 0 !important;
  margin-top: 36px;
  border-right: 5px solid transparent;
  min-width: 189px;
}

.timetable .amounts {
  width: 80px;
  margin-top: 45px;
  line-height: 25px;
  text-align: right;
}

.timetable .amounts li {
  line-height: 25px;
  margin-bottom: 7px;
  font-weight: 600;
}

.timetable aside li {
  margin-bottom: 7px;
  display: flex;
  flex-direction: column;
}

.timetable aside .row-heading {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1em;
}

.timetable aside .row-subheading {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1em;
}

.timetable ul {
  list-style-type: none;
  margin: 0;
}
.timetable aside li,
.timetable time li {
  height: 25px;
}
.timetable section {
  width: 1105px;
  padding: 0 !important;
  -webkit-overflow-scrolling: touch;
}
.timetable section time {
  white-space: nowrap;
  display: block;
}
.timetable section header {
  height: 46px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  font-size: 0;
  position: sticky;
  top: 26px;
  z-index: 999999999;
}

.timetable section header::after {
  clear: both;
  content: "";
  display: table;
}
.timetable section header li {
  display: inline-block;
  font-size: 1rem;
  overflow: visible;
  width: 0;
  line-height: 25px;
  display: inline-block;
  position: relative;
}
.timetable section header li:not(:last-of-type) {
  width: 46px;
}
.timetable section header li .time-label {
  display: block;
  position: absolute;
  left: 0;
  font-size: 0.8em;
  font-weight: 500;
}
.timetable section header li:not(:first-of-type) .time-label {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.timetable section header li:last-of-type .time-label {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.timetable ul.room-timeline {
  border-left: none;
  position: relative;
  overflow: hidden;
}
.timetable ul.room-timeline li {
  position: relative;
  margin-bottom: 7px;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  border-left: 1px solid #333;
}

.timetable ul.room-timeline li:first-child .time-entry {
  height: 23px;
}

.timetable ul.room-timeline li:after,
.timetable ul.room-timeline li:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.timetable ul.room-timeline li:after {
  background-image: linear-gradient(
    to right,
    #333,
    #333 1px,
    #f4f4f4 1px,
    #f4f4f4 1px,
    #333 1px,
    #333 1px,
    transparent 1px,
    transparent
  );
  background-size: 46px auto;
  background-position: -2px 0;
}
.timetable .time-entry {
  background-color: #ec6a5e;
  transition: 200ms background-color;
  height: 23px;
  display: block;
  position: absolute;
  z-index: 2;
  padding: 0 10px;
  white-space: normal;
  overflow: hidden;
  color: #000;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.timetable .time-entry.time-entry--pause small {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
}
.timetable .time-entry .cityLeft {
  display: inline-block;
  line-height: 23px;
  font-weight: 600;
}

.timetable .time-entry .cityRight {
  display: block;
  float: right;
  line-height: 23px;
  font-weight: 600;
}

.timetable .time-entry.time-entry--pause {
  background: #333;
  color: #fff;
}

.achtung {
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: red;
  z-index: 10;
}
